<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>

            <div class="search">
                <el-form ref="form" :model="form" label-width="60px" :inline="true">
                    <el-form-item label="楼幢号:">
                        <el-input v-model="form.name" placeholder="请输入楼幢号" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
                    <button @click.prevent="add" style="cursor: pointer;" v-if="false">新建</button>
                </el-form>
            </div>
            <el-card>
            <el-table :data="tableData"
                      style="width:100%"
                      :default-sort="{prop: 'settingTime', order: 'descending'}"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      :stripe="true">
                <el-table-column fixed prop="towerId" label="楼幢id" width="150" v-if="false">
                </el-table-column>
                <el-table-column fixed label="楼幢号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="lookUnit(scope.row)">
                            {{scope.row.towerNum}}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="villageName" label="所属小区" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="towerType" label="楼幢类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="elevatorType" label="是否有电梯" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="floorNum" label="楼层数" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="unitNum" label="单元数" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <row>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
                <el-button @click="back" class="btn" size="small">上一步</el-button>
            </row>
</el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "deveTower",
        components: {
            commonTitle
        },
        data() {
            return {
                form: {
                    name: "",
                    region: '',
                },
                tableData: [],
                multipleSelection: [],
                totalNum: 15,
                url: this.$Config.base_server,
                loading: true,
                data: {
                    access_token: localStorage.getItem("token"),
                    limit: 15,
                    villageId: JSON.parse(localStorage.getItem("villageData")).villageId,
                }
            };
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                const that = this;
                that.data.page = currentPage;
                that.data.towerNum = that.form.name;
                $.ajax({
                    url: this.url + "/api-public/opetower/list",
                    data: that.data,
                    contentType: 'application/json',
                    type: 'get',
                    success: function (result) {
                        that.loading = false;
                        if (result.code == 0) {
                            that.tableData = result.data;
                            that.totalNum = result.count;
                        }
                    }
                });
            },
            toHuShi(data) {
                this.$router.push({name: "batchProduct", params: data})
            },
            back() {
                if(JSON.parse(localStorage.getItem("user")).type==2){
                         this.$router.push('/hoaVillage');
                }
                 if(JSON.parse(localStorage.getItem("user")).type==0){
                         this.$router.push('/proVillage');
                }
               
            },
            update(row) {
                //修改楼幢的页面
                this.$router.push({name: 'upTower', params: {towerData: row}});  //传过去选中的这一行值
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            },
            lookUnit(row) {
                // localStorage.setItem("tower", JSON.stringify(row));
                // window.location.href = "deveUnit.html";
                this.$router.push({name: 'proUnit'});//跳转到单元页面 并带过去选中楼幢的值
                //存储楼幢信息
                localStorage.setItem("towerData", JSON.stringify(row));
            },
            deleteRow(index, rows, tableData) {
                //删除按钮
                let _this = this;
                let _rows = rows;
                //删除相应楼幢
                debugger;
                this.$confirm('此操作将永久删除该楼幢信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    $.ajax({
                        url: _this.url + "/api-public/opetower/delete/" + _rows.towerId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                tableData.splice(index, 1);
                                //成功
                                _this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });
                }).catch(function () {

                });
            }, add() {
                //新增按钮
                this.$router.push({name: 'addTower'});
            }, lookUnit(row) {
                this.$router.push({name: 'proUnit'});//跳转到单元页面 并带过去选中楼幢的值
                //存储楼幢信息
                localStorage.setItem("towerData", JSON.stringify(row));
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-38px);
    }
</style>